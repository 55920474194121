import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../authentication/model/user.model';
import {
  changeUserStatusSuccess,
  fetchAllUsersFailure,
  fetchAllUsersSuccess
} from './admin.action';

export default interface AdminState {
  users: User[];
}
export const initialState: AdminState = {
  users: [],
};

const adminReducer = createReducer(
  initialState,
  on(fetchAllUsersSuccess, (state, action): AdminState => ({ ...state, users: action.users })),
  on(fetchAllUsersFailure, (state, action): AdminState => ({ ...state, users: [] })),
  on(changeUserStatusSuccess, (state, action) => {
    const users = state.users.map(user => (user.id === action.userId ? { ...user, enabled: action.enabled } : user));
    return { ...state, users };
  })
);
export const reducer = (state: AdminState | undefined, action: Action): AdminState => adminReducer(state, action);
