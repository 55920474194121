import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ArrayPipeModule } from '../shared/array.module';
import { SharedModule } from '../shared/shared.module';
import { StringPipeModule } from '../shared/string.module';
import { NavigationRailComponent } from './navigation-rail.component';

@NgModule({
  declarations: [NavigationRailComponent],
  exports: [NavigationRailComponent],
  imports: [ArrayPipeModule, StringPipeModule, FlexLayoutModule, SharedModule],
})
export class NavigationRailModule {}
