import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../authentication/model/user.model';
import { UserCreation, UserUpdate } from '../model/user-creation.model';

export const USER_BASE_URI = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  constructor(private readonly http: HttpClient) {}

  createNewUser(newUser: UserCreation): Observable<void> {
    return this.http.post<void>(`${USER_BASE_URI}`, newUser);
  }

  updateUser(user: UserUpdate): Observable<void> {
    return this.http.put<void>(`${USER_BASE_URI}/${user.id}`, user);
  }

  fetchAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${USER_BASE_URI}`);
  }

  setUserStatus(userId: number, enabled: boolean): Observable<void> {
    const params = new HttpParams().append('enabled', enabled.toString());
    return this.http.put<void>(`${USER_BASE_URI}/${userId}/status`, null, { params });
  }
}
