import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectSortedMunicipalities } from '../../../building/store/reference-data.selector';
import { MunicipalitySummary } from '../../../building/model/municipality.model';
import { createNewUser } from '../../../admin/store/admin.action';
import { ROLE } from '../../../authentication/model/role.model';
import { UserCreation } from '../../../admin/model/user-creation.model';

@Component({
  selector: 'sibat-new-user',
  template: `
    <form [formGroup]="newUserForm" *transloco="let t; read: 'cockpit'">
      <div fxLayout="row">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>{{ t('lastName') }}</mat-label>
            <input matInput formControlName="lastName" required />
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <div>
            <mat-form-field>
              <mat-label>{{ t('firstName') }}</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('email') }}</mat-label>
              <input matInput formControlName="email" required />
              <mat-error>{{ t('requiredValidEmail') }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('phoneNumber') }}</mat-label>
              <input matInput formControlName="phoneNumber" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('role') }}</mat-label>
              <mat-select formControlName="role" (selectionChange)="selectedValue()" required>
                <mat-option *ngFor="let role of roles" value="{{ role }}">{{ 'cockpit.userRole' | transloco: { role } }} </mat-option>
              </mat-select>
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{ t('language') }}</mat-label>
              <mat-select formControlName="language" required>
                <mat-option value="fr">{{ t('french') }}</mat-option>
                <mat-option value="de">{{ t('german') }}</mat-option>
              </mat-select>
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <sibat-primary-button (clicked)="onSubmit()" [label]="'submit' | transloco"> </sibat-primary-button>
        </div>
        <div>
          <mat-form-field class="municipalities" *ngIf="selectedRole === scpiRole">
            <mat-label>{{ t('municipalities') }}</mat-label>
            <mat-select formControlName="municipalities" multiple panelClass="list-540px">
              <mat-option *ngFor="let municipality of this.municipalities$ | async" value="{{ municipality.nr }}">{{
                municipality.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  roles = Object.values(ROLE);
  scpiRole = ROLE.scpi;
  newUserForm: UntypedFormGroup;
  selectedRole: string;
  municipalities$: Observable<MunicipalitySummary[]>;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.municipalities$ = this.store.select(selectSortedMunicipalities);
    this.selectedRole = '';
    this.newUserForm = this.formBuilder.group({
      lastName: ['', { validators: [Validators.required], updateOn: 'change' }],
      firstName: ['', { validators: [Validators.required], updateOn: 'change' }],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      phoneNumber: ['', { updateOn: 'change' }],
      role: ['', { validators: [Validators.required], updateOn: 'change' }],
      language: ['', { validators: [Validators.required], updateOn: 'change' }],
      municipalities: ['', { updateOn: 'change' }],
    });
  }

  selectedValue() {
    this.selectedRole = this.newUserForm.get('role')?.value;
  }

  onSubmit() {
    const municipalities = this.newUserForm.get('municipalities')?.value;
    const newUser: UserCreation = {
      firstName: this.newUserForm.get('firstName')?.value,
      lastName: this.newUserForm.get('lastName')?.value,
      email: this.newUserForm.get('email')?.value,
      phoneNumber: this.newUserForm.get('phoneNumber')?.value,
      role: this.newUserForm.get('role')?.value,
      language: this.newUserForm.get('language')?.value,
    };
    if (municipalities.length > 0) {
      newUser.accreditations = municipalities;
    }
    this.store.dispatch(createNewUser({ newUser }));
  }
}
