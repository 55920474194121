import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { getLayer, LAYER_NAME } from '../layers/map-layers';
import { BuildingsLayerAttrs } from '../model/map-extent.model';
import { reportMapError } from '../store/map.action';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import * as geometryService from '@arcgis/core/rest/geometryService';
import { orthoService, swissTopoService } from '../basemaps/basemaps';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';
import Point from '@arcgis/core/geometry/Point';
import MapViewProperties = __esri.MapViewProperties;
import { createMapViewCleaner, UsesMapView } from '../utility/map-view-memory';
import { Configuration } from '../../authentication/model/configuration.model';

const SWITZERLAND_COORDINATES_REFERENCE = 2056;

@Component({
  selector: 'sibat-mini-map',
  template: `
    <div class="embed-container">
      <div #mapDiv></div>
      <div id="basemapToggleNodeMini"></div>
    </div>
  `,
  styleUrls: ['./web-map.component.scss'],
})
export class MiniMapComponent implements OnChanges, OnDestroy, UsesMapView {
  @Input() registeredGisToken = false;
  @Input() configuration?: Configuration;
  @Input() buildingId?: number;
  @Input() typology?: string; // its only purpose is to force a refresh when the color changes (not read anywhere)
  @Output() mapLoadedEvent = new EventEmitter<boolean>();

  @ViewChild('mapDiv', { static: true }) private mapDiv: ElementRef;

  view?: MapView;
  buildingLayer?: FeatureLayer;
  mapViewCleaner = createMapViewCleaner();

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.registeredGisToken) {
      const idChange: SimpleChange = changes.buildingId;
      if (idChange && idChange.previousValue && idChange.previousValue !== idChange.currentValue) {
        // buildingId changes when navigating between buildings
        void this.updateView();
      } else if (this.configuration) {
        // typology change
        void this.initialize(this.configuration);
      }
    }
  }

  ngOnDestroy(): void {
    this.mapViewCleaner.cleanup(this);
  }

  addMarkerOnBuilding(geometry: Polygon) {
    geometryService.labelPoints(environment.geometryService, [geometry], {}).then(points => {
      const [point] = points as any as Point[]; // bug: labelPoints returns Promise<Point[]> but signature is Promise<Point>
      const graphic = {
        symbol: {
          type: 'picture-marker',
          angle: 0,
          xoffset: 2,
          yoffset: 8,
          url: environment.markerIcon,
          contentType: 'image/png',
          width: 24,
          height: 24,
        },
        geometry: {
          type: 'point',
          x: point.x,
          y: point.y,
          spatialReference: { wkid: SWITZERLAND_COORDINATES_REFERENCE },
        },
      } as any as Graphic;
      this.view?.graphics.removeAll();
      this.view?.graphics.add(graphic);
    });
  }

  private updateView(): Promise<void> {
    const sqlQueryWhere = `${BuildingsLayerAttrs.BuildingId}=${this.buildingId}`;
    let result: Promise<void> = Promise.resolve();

    if (this.buildingLayer) {
      const query = this.buildingLayer.createQuery();
      // Enter variable of objectID (or other attribute) in there [SQL]
      query.where = sqlQueryWhere;
      query.returnGeometry = true;
      query.outFields = [BuildingsLayerAttrs.ObjectID];

      result = this.buildingLayer.queryFeatures(query).then(resultsBuild => {
        if (resultsBuild.features.length > 0) {
          this.centerOnFeature(resultsBuild.features[0]);
        }
      });
    }

    return result;
  }


  private centerOnFeature(feature): void {
    this.addMarkerOnBuilding(feature.geometry as Polygon);
    this.view
      ?.goTo({
        target: feature, // Graphic object
        zoom: 18,
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          this.store.dispatch(reportMapError({ error }));
        }
      });
  }

  private async initialize(configuration: Configuration): Promise<any> {
    try {
      const swissTopoBasemap = swissTopoService();
      const orthoBasemap = orthoService(configuration);

      const map = new Map({
        basemap: swissTopoBasemap,
      });

      this.view = new MapView({
        container: this.mapDiv.nativeElement,
        map,
      });

      if (!this.view) {
        return Promise.reject(`Error when creating a MapView`);
      }

      await this.view.when();

      this.buildingLayer = getLayer(configuration, LAYER_NAME.buildings);
      map.add(this.buildingLayer);
      map.add(getLayer(configuration, LAYER_NAME.parcelsBorders));
      map.add(getLayer(configuration, LAYER_NAME.parcelsNumbers));

      this.view.ui.add(
        new BasemapToggle({
          view: this.view,
          nextBasemap: orthoBasemap,
          container: 'basemapToggleNodeMini'
        } as MapViewProperties),
        'bottom-right'
      );

      return this.updateView().then(() => {
        this.mapLoadedEvent.emit(true);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
