import { Message } from './../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { Configuration } from '../model/configuration.model';
import { User } from '../model/user.model';
import { Motd } from '../model/motd.model';

export const getUserAfterTokenLoaded = createAction('[User] get user');

export const getUserAfterTokenLoadedFailure = createAction('[User] loaded token could not be used');

export const getUserAfterLoginSuccess = createAction('[User] get user after login');

export const updateUserLanguage = createAction('[User] update user language', props<{ language: string }>());

export const updateUserLanguageSuccess = createAction('[User] update user language success', props<{ language: string }>());

export const updateUserLanguageFailure = createAction('[User] update user language failed');

export const getUserAfterLoginSuccessFailure = createAction('[User] could get user after successful login');

export const getUserSuccess = createAction('[User] get user success', props<{ user: User }>());

export const getUserSuccessWithRedirect = createAction('[User] get user success with redirect', props<{ user: User }>());

export const clearUser = createAction('[User] clear current user');

export const registerGisToken = createAction('[User] register GIS read token', props<{ user: User; arcGisUrl: string }>());

export const registerGisTokenSuccess = createAction('[User] successfully registered GIS read token');

export const registerGisTokenFailure = createAction(
  '[User] failed register GIS read token',
  props<{ error: Error; notification: Message }>()
);

export const getConfigSuccess = createAction('[User] get config success', props<{ user: User; configuration: Configuration }>());

export const showMotd = createAction('[User] show MOTD', props<{ motd: Motd }>());

export const markMotdAsRead = createAction('[User] mark MOTD as read', props<{ uuid: string }>());
