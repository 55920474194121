import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { selectIsSpecialistOrAbove, selectIsSecretary } from 'src/app/authentication/store/user.selector';
import { createTextTranslation } from 'src/app/shared/localization';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FabButton, FabService } from 'src/app/shared/service/fab.service';
import { BatchTypologyEditDialogComponent } from '../batch-typology-edit-dialog/batch-typology-edit-dialog.component';
import { BuildingSummary } from '../model/building.model';
import { UpdateTypologyDto } from '../model/typology.dto';
import { DOWNLOAD_BUILDINGS_LIST_SIZE } from '../service/building.service';
import { updateNextControlDates, updateTypologies } from '../store/building.action';
import { downloadBuildingList } from '../store/buildings.action';
import { selectSelectedBuildings } from '../store/buildings.selector';
import {
  BatchNextControlDateEditDialogComponent
} from '../batch-next-control-date-edit-dialog/batch-next-control-date-edit-dialog.component';

interface RemovableBuilding extends BuildingSummary {
  removed: boolean;
}

@Component({
  selector: 'sibat-detailed-list',
  templateUrl: './detailed-list.component.html',
  styleUrls: ['./detailed-list.component.scss'],
})
export class DetailedListComponent implements AfterViewInit, OnDestroy, OnInit {
  @Output() hide = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<RemovableBuilding>([]);
  displayedColumns = ['select', 'address', 'typology', 'assignment', 'lastControlDate', 'nextControlDate'];

  isSpecialistOrAbove$ = this.store.select(selectIsSpecialistOrAbove);
  isSecretary$ = this.store.select(selectIsSecretary);

  /**
   * True if at least one building is removed but False if all buildings are removed
   * This is important because we dont want to show the "intermediate" check-mark if all buildings are removed
   */
  someRemoved = false;
  noneRemoved = true;

  readonly editControlDateFab = new FabButton({
    label: createTextTranslation('building.control.editNextDate'),
    action: () => this.editNextControlDate(),
  });
  readonly editTypologyFab = new FabButton({
    label: createTextTranslation('building.typology.edit'),
    action: () => this.editTypology(),
  });
  readonly exportSelectionFab = new FabButton({
    label: createTextTranslation('building.list.exportTheSelection'),
    action: () => this.exportSelection(),
    tooltip: createTextTranslation('building.list.downloadTooltip', { maxDownloadSize: DOWNLOAD_BUILDINGS_LIST_SIZE }),
    icon: 'cloud_download',
  });

  private readonly subscriptions = new Subscription();

  constructor(private store: Store, private fabService: FabService, private dialogService: DialogService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([this.isSpecialistOrAbove$, this.isSecretary$])
        .subscribe(([canEdit, canOnlyExport]) => {
          if (canEdit) {
            this.fabService.setFabOptions({
              buttons: [this.editControlDateFab, this.editTypologyFab, this.exportSelectionFab],
            });
          }

          if (canOnlyExport) {
            this.fabService.setFabOptions({
              buttons: [this.exportSelectionFab],
            });
          }
        })
    );
    this.subscriptions.add(
      this.store.select(selectSelectedBuildings).subscribe(buildings => {
        this.dataSource.data = buildings.map(building =>
          this.remove(building, this.dataSource.data.find(b => b.id === building.id)?.removed ?? false)
        );
      })
    );
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (building, property) => {
      switch (property) {
        case 'address':
          return building.addresses.join('');
        case 'assignment':
          return building.localizedAffectations.join('');
        case 'typology':
          return building.currentColor ?? '';
        case 'lastControlDate':
          return building.lastControlDate ?? 0;
        case 'nextControlDate':
          return building.nextControlDate ?? 0;
        default:
          return String(building[property]);
      }
    };
  }

  buildingId: TrackByFunction<RemovableBuilding> = (_, item) => item.id;

  removeAll(allChecked: boolean) {
    this.noneRemoved = allChecked;
    this.someRemoved = false;
    this.dataSource.data = this.dataSource.data.map(building => this.remove(building, !allChecked));
    this.updateFab();
  }

  removeOne(building: RemovableBuilding, checked: boolean) {
    this.dataSource.data = this.dataSource.data.map(b => (b.id === building.id ? this.remove(b, !checked) : b));
    const [someRemoved, allRemoved] = this.dataSource.data.reduce(
      ([some, none], b) => [some || b.removed, none && b.removed],
      [false, true]
    );

    this.someRemoved = someRemoved && !allRemoved;
    this.noneRemoved = !someRemoved;
    this.updateFab();
  }

  hideFabAndSelf = () => {
    this.fabService.clearFabOptions();
    this.hide.emit();
  };

  private remove(building: BuildingSummary, removed = true): RemovableBuilding {
    return { ...building, removed };
  }

  private async editNextControlDate() {
    const buildings = this.dataSource.data.filter(b => !b.removed);
    const count = buildings.length;
    const nextControlDate = await this.dialogService.openDialogComponentAsync<string | null>(BatchNextControlDateEditDialogComponent, {
      count,
    });

    if (nextControlDate || nextControlDate === null) {
      this.store.dispatch(
        updateNextControlDates({
          buildingIds: buildings.map(b => b.id),
          date: nextControlDate,
        })
      );
    }
  }

  private async editTypology() {
    const buildings = this.dataSource.data.filter(b => !b.removed);
    const count = buildings.length;
    const typology = await this.dialogService.openDialogComponentAsync<UpdateTypologyDto>(BatchTypologyEditDialogComponent, {
      count,
    });

    if (typology) {
      this.store.dispatch(
        updateTypologies({
          buildingIds: buildings.map(b => b.id),
          typology,
        })
      );
    }
  }

  private updateFab() {
    const hasSelection = this.someRemoved || this.noneRemoved;
    this.editTypologyFab.disabled = !hasSelection;
    this.exportSelectionFab.disabled = !hasSelection;
    this.fabService.disableFab(!hasSelection);
  }

  private async exportSelection() {
    const selectedBuildingIds = this.dataSource.data.reduce((buildingIds, building) => {
      if (!building.removed) {
        buildingIds.push(building.id);
      }
      return buildingIds;
    }, [] as number[]);

    this.store.dispatch(downloadBuildingList({ buildingIds: selectedBuildingIds }));
  }
}
