import { MunicipalitySummary } from '../../building/model/municipality.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'municipalityNames',
})
export class MunicipalityNamesPipe implements PipeTransform {
  transform(value: Array<MunicipalitySummary>): string {
    return value.map(v => v.name).join(', ');
  }
}
