import { Message } from '../../model/notification.model';
import { UserUpdate } from '../model/user-creation.model';
import { createAction, props } from '@ngrx/store';
import { UserCreation } from '../model/user-creation.model';
import { User } from '../../authentication/model/user.model';

export const createNewUser = createAction('[Cockpit] create new user', props<{ newUser: UserCreation }>());

export const createNewUserSuccess = createAction('[Cockpit] create new user success', props<{ notification: Message }>());

export const createNewUserFailure = createAction('[Cockpit] create new user failure', props<{ error: any; notification: Message }>());

export const updateUser = createAction('[Cockpit] update new user', props<{ user: UserUpdate }>());

export const updateUserSuccess = createAction('[Cockpit] update user success');

export const updateUserFailure = createAction('[Cockpit] update user failure', props<{ error: any; notification: Message }>());

export const fetchAllUsers = createAction('[User] fetch all user');

export const fetchAllUsersSuccess = createAction('[User] fetch all user success', props<{ users: User[] }>());

export const fetchAllUsersFailure = createAction('[User] fetch all user failure', props<{ error: any }>());

export const changeUserStatus = createAction('[Admin] change user status', props<{ userId: number; enabled: boolean }>());

export const changeUserStatusSuccess = createAction('[Admin] change user status success', props<{ userId: number; enabled: boolean }>());

export const changeUserStatusFailure = createAction('[Admin] change user status failure', props<{ error: any; notification: Message }>());
