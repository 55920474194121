import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '../authentication/store/authentication.action';
import { User } from '../authentication/model/user.model';
import { updateUserLanguage } from '../authentication/store/user.action';
import { TranslocoService } from '@ngneat/transloco';
import { selectComponentNavRoot } from '../router/store/router-store.selector';
import { selectIsAdmin, selectIsECABOrAbove } from '../authentication/store/user.selector';
import { getEnvironmentConfig } from '../environment-config/store/environment-config.action';
import { selectIsNotProdEnv, selectShortEnvName } from '../environment-config/store/environment-config.selector';
import { environment } from '../../environments/environment';

@Component({
  selector: 'sibat-navigation-rail',
  template: `
    <div class="navigation-rail-container" [class.not-prod]="isNotProdEnv$ | async">
      <nav class="navigation-rail mat-elevation-z4">
        <div class="navigation-rail-top" fxHide.xs="true">
          <a routerLink="/" class="navigation-rail-logo"><img src="assets/logo.png" alt="{{ 'layout.nav.brand' | transloco }}" /></a>
          <h1 class="navigation-rail-title">SIBAT</h1>
          <div *ngIf="isNotProdEnv$ | async" class="env-config">{{ envName$ | async }}</div>
          <span class="navigation-rail-language">
            <a (click)="selectLanguage('fr')" [ngClass]="{ active: (lang$ | async) === 'fr' }">FR</a>
            <a (click)="selectLanguage('de')" [ngClass]="{ active: (lang$ | async) === 'de' }">DE</a>
            <a (click)="selectLanguage('keys')" [ngClass]="{ active: (lang$ | async) === 'keys' }" *ngIf="isAdmin$ | async">keys</a>
          </span>
        </div>
        <div class="navigation-rail-middle" fxLayout.gt-xs="column" fxLayout.xs="row">
          <div class="nav-button">
            <button
              mat-icon-button
              class="navigation-rail-button"
              [ngClass]="{ selected: 'cockpit' === (navRoot$ | async) }"
              [disabled]="user === null"
              routerLink="/cockpit"
            >
              <mat-icon>dashboard</mat-icon>
            </button>
            <span class="visually-hidden">{{ 'layout.nav.cockpit' | transloco }}</span>
            <div fxHide.gt-xs="true">{{ 'layout.nav.cockpit' | transloco }}</div>
          </div>
          <div class="nav-button">
            <button
              mat-icon-button
              class="navigation-rail-button"
              [ngClass]="{ selected: 'map' === (navRoot$ | async) }"
              [disabled]="user === null"
              routerLink="/"
            >
              <mat-icon>map</mat-icon>
            </button>
            <span class="visually-hidden">{{ 'layout.nav.map' | transloco }}</span>
            <div fxHide.gt-xs="true">{{ 'layout.nav.map_short' | transloco }}</div>
          </div>
<!--          <ng-container *ngIf="sinboardUrl$ | async as sinboardUrl">-->
<!--            <div class="nav-button" *ngIf="isECABOrAbove$ | async">-->
<!--              <a-->
<!--                mat-icon-button-->
<!--                class="navigation-rail-button"-->
<!--                target="_blank"-->
<!--                rel="noopener noreferrer"-->
<!--                [href]="sinboardUrl"-->
<!--              >-->
<!--                <mat-icon>thunderstorm</mat-icon>-->
<!--              </a>-->
<!--              <div fxHide.gt-xs="true">Sinboard</div>-->
<!--            </div>-->
<!--          </ng-container>-->
          <div class="nav-button" fxHide.gt-xs="true">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="navigation-rail-button">
              <mat-icon>more_vert</mat-icon>
            </button>
            <div>{{ 'layout.nav.more' | transloco }}</div>
          </div>
          <mat-menu #menu="matMenu" fxHide.gt-xs="true">
            <button mat-menu-item [matMenuTriggerFor]="languages" fxHide.gt-xs="true">
              <mat-icon>language</mat-icon>
              <span>{{ 'cockpit.language' | transloco }}</span>
            </button>
            <button mat-menu-item *ngIf="user" fxHide.gt-xs="true" (click)="openHelp()">
              <mat-icon>open_in_new</mat-icon>
              <span>{{ 'layout.nav.help' | transloco }}</span>
            </button>
            <button mat-menu-item *ngIf="user" fxHide.gt-xs="true" (click)="createIssue()">
              <mat-icon>open_in_new</mat-icon>
              <span>feedback</span>
            </button>
            <button mat-menu-item fxHide.gt-xs="true" (click)="logout()">
              <mat-icon>power_settings_new</mat-icon>
              <span>{{ 'layout.nav.logout' | transloco }}</span>
            </button>
          </mat-menu>
          <mat-menu #languages="matMenu" fxHide.gt-xs="true">
            <button mat-menu-item fxHide.gt-xs="true" (click)="selectLanguage('de')">
              <span>DE</span>
            </button>
            <button mat-menu-item fxHide.gt-xs="true" (click)="selectLanguage('fr')">
              <span>FR</span>
            </button>
            <button mat-menu-item fxHide.gt-xs="true" (click)="selectLanguage('keys')" *ngIf="isAdmin$ | async">
              <span>keys</span>
            </button>
          </mat-menu>
        </div>
        <div class="navigation-rail-bottom" fxHide.xs="true">
          <ng-container *ngIf="user">
            <a class="navigation-rail-button link-button"
               mat-button
               data-testid="help-link"
               (click)="openHelp()"
            >{{ 'layout.nav.help' | transloco }}
              <mat-icon class="open-in-new">open_in_new</mat-icon>
            </a>
            <a class="navigation-rail-button link-button"
               mat-button
               data-testid="feedback-link"
               (click)="createIssue()"
            >feed<br>back
              <mat-icon class="open-in-new">open_in_new</mat-icon>
            </a>
          </ng-container>
          <button mat-button [disabled]="!user" class="navigation-rail-button" (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span class="visually-hidden">{{ 'layout.nav.logout' | transloco }}</span>
          </button>
        </div>
      </nav>
      <main class="navigation-rail-content" #content>
        <ng-content></ng-content>
      </main>
    </div>
  `,
  styleUrls: ['navigation-rail.component.scss'],
})
export class NavigationRailComponent implements OnInit {
  @Input() user?: User;
  envName$ = this.store.select(selectShortEnvName);
  isNotProdEnv$ = this.store.select(selectIsNotProdEnv);
  isAdmin$ = this.store.select(selectIsAdmin);
  lang$ = this.translocoService.langChanges$;
  navRoot$ = this.store.select(selectComponentNavRoot);
  isECABOrAbove$ = this.store.select(selectIsECABOrAbove);

  constructor(private store: Store, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.store.dispatch(getEnvironmentConfig());
  }

  logout() {
    this.store.dispatch(logout());
  }

  selectLanguage(language: string) {
    this.store.dispatch(updateUserLanguage({ language }));
  }

  openHelp = () => window.open(environment.support.helpLink, '_blank');

  createIssue = () => window.open(environment.support.feedbackLink, '_blank');

}
